<template>
  <v-container fluid class="pa-0">
    <v-img
      class="white--text align-center d-flex"
      :height="$vuetify.breakpoint.mdAndUp ? '100vh' : '95vh'"
      :src="
        $vuetify.breakpoint.mdAndUp
          ? require('@/assets/images/HomePage/Landing.png')
          : require('@/assets/images/HomePage/Landing_Mobile.png')
      "
    >
      <div class="d-flex flex-column">
        <v-spacer />
        <div
          class="d-flex flex-column ma-auto align-center"
          :style="$vuetify.breakpoint.mdAndUp ? 'gap: 20px' : 'gap:12px'"
        >
          <span
            class="bold text-center"
            :class="
              ($vuetify.theme.dark
                ? 'onSurface_dark--text'
                : 'background_light--text') +
              ' ' +
              ($vuetify.breakpoint.lgAndUp
                ? 'h1'
                : $vuetify.breakpoint.mdAndUp
                ? 'h2'
                : 'h3')
            "
            >{{ $t("home.subtitle") }}</span
          >

          <v-img
            :max-height="
              $vuetify.breakpoint.lgAndUp
                ? '101px'
                : $vuetify.breakpoint.mdAndUp
                ? '70px'
                : '55px'
            "
            width="100%"
            contain
            :src="logoSrc"
          />
        </div>
        <v-spacer />
        <v-overlay
          absolute
          opacity="0"
          class="d-flex flex-column justify-end pb-3"
          :class="$vuetify.breakpoint.mdAndUp ? 'pb-3' : 'pb-2'"
        >
          <div
            class="d-flex flex-column pa-3 align-center"
            :class="
              $vuetify.theme.dark
                ? 'onSurface_dark--text'
                : 'background_light--text'
            "
            :style="$vuetify.breakpoint.mdAndUp ? 'gap: 8px' : 'gap:14px'"
          >
            <span
              class="text-center"
              :class="$vuetify.breakpoint.mdAndUp ? 'h3 bold' : 'title-1 bold'"
              >{{ $t("home.explore") }}</span
            >
            <v-img
              :width="$vuetify.breakpoint.mdAndUp ? 40 : 22"
              src="@/assets/icons/Mouse.svg"
            />
          </div>
        </v-overlay>
      </div>
    </v-img>
  </v-container>
</template>

<script>
export default {
  components: {},
  computed: {
    logoSrc() {
      const language = this.$i18n.locale === "en" ? "en" : "ko";
      //const theme = this.$vuetify.theme.dark ? "Dark" : "Light";
      return require(`@/assets/branding/Logo(${language})_Light.svg`);
    },
  },
  data() {
    return {};
  },
  methods: {},
};
</script>

<style></style>
