<template>
  <v-container fluid class="pa-0">
    <landing-section />
    <main-section />
    <discover-section />
  </v-container>
</template>

<style></style>

<script>
import setMeta from "@/utils/setMeta";
import LandingSection from "./Components/LandingSection.vue";
import MainSection from "./Components/MainSection.vue";
import DiscoverSection from "./Components/DiscoverSection.vue";

export default {
  components: {
    LandingSection,
    MainSection,
    DiscoverSection,
  },
  name: "Home",
  beforeCreate() {
    setMeta({
      title: "홈",
      description: "메이커 문화의 중심 메이드올입니다.",
      image:
        "https://firebasestorage.googleapis.com/v0/b/madeall.appspot.com/o/Assets%2FOG%2Fmain.png?alt=media&token=d87cafdc-d36d-428c-bc9d-44d66386d74b",
    });
  },
  data() {
    return {};
  },

  methods: {
    responsiveClass(e1, e2, e3) {
      return this.$vuetify.breakpoint.mdAndUp
        ? this.$vuetify.breakpoint.lgAndUp
          ? e1
          : e2
        : e3;
    },
    notReady() {
      alert("준비중입니다.");
    },
    openLink(link) {
      window.open(link);
    },
    routerPush(routerName) {
      if (routerName === "") {
        return;
      }
      if (this.$router.currentRoute.name !== routerName) {
        this.$router
          .push({ name: routerName })
          .then(() => window.scrollTo(0, 0));
      }
    },
  },
};
</script>
