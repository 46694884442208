<template>
  <v-container fluid class="pa-0">
    <v-img
      :src="
        $vuetify.breakpoint.mdAndUp
          ? require('@/assets/images/HomePage/NewsSection.png')
          : require('@/assets/images/HomePage/NewsSection_Mobile.png')
      "
      class="d-flex align-center justify-center"
      max-height="380px"
      min-height="280px"
      :height="$vuetify.breakpoint.mdAndUp ? '' : '280px'"
    >
      <div
        class="d-flex flex-column align-center justify-center my-auto"
        style="gap: 24px"
      >
        <span
          :class="
            responsiveClass('h2', 'h3', 'title-1') +
            ' ' +
            ($vuetify.theme.dark
              ? 'onSurface_dark--text'
              : 'background_light--text')
          "
          class="bold text-center"
          >{{ $t("home.discover") }}</span
        >
        <v-btn
          elevation="0"
          :height="responsiveClass(48, 44, 34)"
          color="primary"
          :class="$vuetify.breakpoint.lgAndUp ? 'title-2' : 'subtitle-1'"
          class="regular px-4 rounded-lg"
          @click="routerPush('Press')"
          >{{ $t("home.btnNews") }}</v-btn
        >
      </div>
    </v-img>
  </v-container>
</template>

<script>
export default {
  components: {},
  computed: {
    logoSrc() {
      const language = this.$i18n.locale === "en" ? "en" : "ko";
      //const theme = this.$vuetify.theme.dark ? "Dark" : "Light";
      return require(`@/assets/branding/Logo(${language})_Light.svg`);
    },
  },
  data() {
    return {};
  },
  methods: {
    responsiveClass(e1, e2, e3) {
      return this.$vuetify.breakpoint.mdAndUp
        ? this.$vuetify.breakpoint.lgAndUp
          ? e1
          : e2
        : e3;
    },
    notReady() {
      alert("준비중입니다.");
    },
    openLink(link) {
      window.open(link);
    },
    routerPush(routerName) {
      if (routerName === "") {
        return;
      }
      if (this.$router.currentRoute.name !== routerName) {
        this.$router
          .push({ name: routerName })
          .then(() => window.scrollTo(0, 0));
      }
    },
  },
};
</script>

<style></style>
