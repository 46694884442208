<template>
  <v-container fluid class="pa-0">
    <div
      v-for="data in datas"
      :key="data.id"
      class="pa-0 d-flex"
      :class="
        data.isReverse
          ? $vuetify.breakpoint.mdAndUp
            ? 'flex-row-reverse'
            : 'flex-column-reverse'
          : $vuetify.breakpoint.mdAndUp
          ? 'flex-row'
          : 'flex-column-reverse align-end'
      "
      :style="
        $vuetify.breakpoint.mdAndUp
          ? 'margin-bottom: 160px'
          : 'margin-bottom: 80px'
      "
    >
      <div
        :style="
          responsiveClass(
            `margin: 154px ${data.isReverse ? '5.21vw' : '0'} 154px ${
              data.isReverse ? '0' : '5.21vw'
            }`,
            `margin: 154px ${data.isReverse ? '12px' : '0'} 154px ${
              data.isReverse ? '0' : '12px'
            }`,
            '',
          )
        "
        class="d-flex"
        :class="
          data.isReverse
            ? responsiveClass('ml-1', 'ml-3', 'ml-4') + ' justify-start'
            : responsiveClass('mr-1', 'mr-3', 'mr-4') + ' justify-end'
        "
      >
        <div
          class="my-auto d-flex flex-column onSurface_dark--text"
          :class="
            data.isReverse ^ $vuetify.breakpoint.mdAndUp
              ? 'align-start text-start'
              : 'align-end text-end'
          "
          :style="
            responsiveClass(
              `gap:40px; min-width:${data.width ? data.width : '530'}px`,
              `gap:40px; min-width:${data.mdWidth ? data.mdWidth : '320'}px`,
              'gap:12px',
            )
          "
        >
          <span :class="responsiveClass('h1 bold', 'h2 bold', 'h3 bold')">{{
            $t("home." + data.title)
          }}</span>
          <span
            :class="
              $vuetify.breakpoint.xs
                ? 'subtitle-2'
                : responsiveClass('h3', 'title-2 regular', 'title-2 regular')
            "
            >{{ $t("home." + data.subtitle1) }}<br />{{
              $t("home." + data.subtitle2)
            }}</span
          >
          <v-btn
            elevation="0"
            color="primary"
            class="px-4 regular rounded-lg"
            :class="$vuetify.breakpoint.mdAndUp ? 'title-2' : 'subtitle-1'"
            :height="$vuetify.breakpoint.mdAndUp ? 44 : 34"
            @click="buttonPressed(data.btn)"
          >
            {{
              $t("home." + (data.btn.title ? data.btn.title : "btnGo"))
            }}</v-btn
          >
        </div>
      </div>
      <v-spacer :style="$vuetify.breakpoint.mdAndUp ? '' : 'height:24px'" />
      <v-img
        :height="
          responsiveClass(
            data.height ? data.height : '600px',
            '600px',
            data.mobileHeight ? data.mobileHeight : '220px',
          )
        "
        :max-width="$vuetify.breakpoint.mdAndUp ? '59vw' : '345px'"
        :src="require(`@/assets/images/HomePage/${data.image}.png`)"
        :style="
          data.isReverse
            ? `border-radius: 0 ${
                data.imgRound.up
                  ? $vuetify.breakpoint.mdAndUp
                    ? '300px'
                    : '110px'
                  : '0'
              } ${
                data.imgRound.down
                  ? $vuetify.breakpoint.mdAndUp
                    ? '300px'
                    : '110px'
                  : '0'
              } 0`
            : `border-radius: ${
                data.imgRound.up
                  ? $vuetify.breakpoint.mdAndUp
                    ? '300px'
                    : '110px'
                  : '0'
              } 0 0 ${
                data.imgRound.down
                  ? $vuetify.breakpoint.mdAndUp
                    ? '300px'
                    : '110px'
                  : '0'
              }`
        "
        :position="data.imgPos ? data.imgPos : 'center center'"
      />
    </div>
  </v-container>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      default: () => {
        //주의: 아래 디폴트값은 어디까지나 참고용입니다. 실제로는 적용되지 않습니다.
        return {
          title: "title",
          subtitle1: "subtitle1",
          subtitle2: "subtitle2",
          btn: "btnGo",
          image: "Landing",
          imgRound: { up: true, down: true },
          imgPos: "center center",
          isReverse: true,
          width: 550,
          mdWidth: 380,
          height: 600,
          mobileHeight: 220,
        };
      },
    },
  },
  components: {},
  data() {
    return {
      datas: [
        {
          title: "manufactureTitle",
          subtitle1: "manufactureSubtitle1",
          subtitle2: "manufactureSubtitle2",
          btn: { title: "btnGo", link: "Service" },
          image: "manufacturing",
          imgRound: { up: false, down: true },
          isReverse: true,
          height: 780,
          mobileHeight: 290,
        },
        {
          title: "printerTitle",
          subtitle1: "printerSubtitle1",
          subtitle2: "printerSubtitle2",
          btn: { title: "btnMore", link: "Products" },
          image: "printer",
          imgRound: { up: true, down: true },
          imgPos: "5% center",
          isReverse: false,
          mdWidth: 350,
        },
        {
          title: "makerEduTitle",
          subtitle1: "makerEduSubtitle1",
          subtitle2: "makerEduSubtitle2",
          btn: { title: "btnMore", link: "Education" },
          image: "edu",
          imgRound: { up: true, down: true },
          imgPos: "60% center",
          isReverse: true,
          mdWidth: 350,
        },
        {
          title: "makerSpaceTitle",
          subtitle1: "makerSpaceSubtitle1",
          subtitle2: "makerSpaceSubtitle2",
          btn: { title: "btnMore" },
          image: "makerspace",
          imgRound: { up: true, down: true },
          imgPos: "25% center",
          isReverse: false,
        },
        {
          title: "onlineStoreTitle",
          subtitle1: "onlineStoreSubtitle1",
          subtitle2: "onlineStoreSubtitle2",
          btn: {
            title: "btnExplore",
            externalLink: "https://smartstore.naver.com/madeall",
          },
          image: "onlinestore",
          imgRound: { up: true, down: true },
          imgPos: "40% center",
          isReverse: true,
          mdWidth: 380,
        },
      ],
    };
  },
  methods: {
    responsiveClass(e1, e2, e3) {
      return this.$vuetify.breakpoint.mdAndUp
        ? this.$vuetify.breakpoint.lgAndUp
          ? e1
          : e2
        : e3;
    },
    notReady() {
      alert("준비중입니다.");
    },
    openLink(link) {
      window.open(link);
    },
    routerPush(routerName) {
      if (routerName === "") {
        return;
      }
      if (this.$router.currentRoute.name !== routerName) {
        this.$router
          .push({ name: routerName })
          .then(() => window.scrollTo(0, 0));
      }
    },
    buttonPressed(btn) {
      if (btn.externalLink) {
        window.open(btn.externalLink);
      } else if (btn.link) {
        this.routerPush(btn.link);
      } else {
        this.notReady();
      }
    },
  },
};
</script>

<style></style>
